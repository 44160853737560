import { Box, Container, LinearProgress, Link, Stack, Typography } from "@mui/material"
import { LGTContractVersion, TPublicTemplate } from "./utils/network/contract"
import { useAllSkusQuery } from "./utils/queries/useAllSkusQuery";

const SkuList = () => {
  const { data: allSkus, isLoading } = useAllSkusQuery();

  const nftDistributionSkus = allSkus?.secondary_contracts;

  return (
    <Container maxWidth='xl'>
      <Stack spacing={4} textAlign='center'>
        <Typography variant='h2'>Leaderboard</Typography>
        {isLoading && <LinearProgress />}
        {nftDistributionSkus
          ?.filter(sku => sku.secondary_contract_version === LGTContractVersion.ERC1155_NFT_DISTRIBUTION)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((sku: TPublicTemplate) => {
          return (
            <Box key={sku.name}>
              <Link href={`/item?chainId=${sku.nft_chain_id}&primary_contract=${sku.nft_contract_address}&secondary_contract=${sku.secondary_nft_contract_address}`}>
                <Typography variant='h5'>
                  {sku.name}
                </Typography>
              </Link>
            </Box>
          )
        })}
      </Stack>
    </Container>
  )
}

export default SkuList
