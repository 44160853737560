import { Box, Container, LinearProgress, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useAllSkusQuery } from "./utils/queries/useAllSkusQuery";
import { useSearchParams } from "react-router-dom";
import { NETWORK, RPC_URL_BY_CHAIN_ID, generateAddressLink } from "./utils/web3";
import { useNftDistributedQuery } from "./utils/queries/useNftDistributedQuery";
import { useNftCollectedQuery } from "./utils/queries/useNftCollectedQuery";
import { JsonRpcProvider } from "ethers";
import EnsOrAddressDisplay from "./components/EnsOrAddressDisplay";

const Item = () => {
  const { data: allSkus } = useAllSkusQuery();
  const [searchParams] = useSearchParams();
  const ensProvider = new JsonRpcProvider(RPC_URL_BY_CHAIN_ID[1], 1, {
    batchMaxCount: 10, // max limit on the batches from our RPC provider
  })

  const chainId = Number(searchParams.get('chainId')) as NETWORK;
  const primaryContract = searchParams.get('primary_contract') || '';
  const secondaryContract = searchParams.get('secondary_contract') || '';

  const sku = allSkus?.secondary_contracts.find(sku => primaryContract === sku.nft_contract_address && secondaryContract === sku.secondary_nft_contract_address);

  const { data: nftDistributed, isLoading: isDistributedLoading} = useNftDistributedQuery({
    chainId: chainId,
    primaryContractAddress: primaryContract,
    secondaryContractAddress: secondaryContract,
  });

  const { data: nftCollected, isLoading: isCollectedLoading } = useNftCollectedQuery({
    chainId: chainId,
    secondaryContractAddress: secondaryContract,
  });

  return (
    <>
      <Container maxWidth='xl'>
        <Stack spacing={4}>
          <Typography variant='h2'>{sku?.name || ' '}</Typography>
          {/* <Typography variant="h5" sx={{ fontFamily: 'Tiempos', fontWeight: 'normal' }}>Leaderboard</Typography> */}
          {(isDistributedLoading || isCollectedLoading) && <LinearProgress />}
          <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
            <Stack width={{ xs: '100%', md: '50%' }} spacing={2}>
              <Typography variant="h5">NFTs Distributed</Typography>
              <TableContainer component={Box}>
                <Table aria-label="NFTs Distributed">
                  <TableHead>
                    <TableRow>
                      <TableCell>Rank</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell align="right">Distributed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {nftDistributed?.map((item, index) => <TableRow key={item.tokenId}>
                        <TableCell component="th" scope="row">{index + 1}.</TableCell>
                        <TableCell>#{item.tokenId}</TableCell>
                        <TableCell>
                          <Link href={generateAddressLink(chainId, item.ownerAddress)} target="_blank">
                            <EnsOrAddressDisplay address={item.ownerAddress} provider={ensProvider}/>
                          </Link>
                        </TableCell>
                        <TableCell align="right">{item.nftsDistributed}</TableCell>
                      </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack >
            <Stack width={{ xs: '100%', md: '50%' }} spacing={2}>
              <Typography variant="h5">NFTs Collected</Typography>
              <TableContainer component={Box}>
                <Table aria-label="NFTs Collected">
                  <TableHead>
                    <TableRow>
                      <TableCell>Rank</TableCell>
                      <TableCell>Collector</TableCell>
                      <TableCell align="right">Collected</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {nftCollected?.map((owner, index) => <TableRow key={owner.ownerAddress}>
                        <TableCell component="th" scope="row">{index + 1}.</TableCell>
                        <TableCell>
                          <Link href={generateAddressLink(chainId, owner.ownerAddress)} target="_blank">
                            <EnsOrAddressDisplay address={owner.ownerAddress} provider={ensProvider}/>
                          </Link>
                        </TableCell>
                        <TableCell align="right">{owner.nftsCollected}</TableCell>
                      </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default Item
