import {useQuery} from 'react-query';
import { TNftDistribution, getNftDistribution } from '../network/leaderboard';
import { NETWORK } from '../web3';

export function useNftDistributedQuery({
  chainId,
  primaryContractAddress,
  secondaryContractAddress
}: {
  chainId: NETWORK,
  primaryContractAddress: string,
  secondaryContractAddress: string
}) {

  return useQuery<Array<TNftDistribution>>(['nft-distribution', chainId, primaryContractAddress, secondaryContractAddress], async () => {
    return (
      await getNftDistribution({
        chainId,
        primaryContractAddress,
        secondaryContractAddress
      })
    );
  },
  {
    refetchInterval: 600000, // refresh every 10 minutes
  });
}
