import {useQuery} from 'react-query';
import { TNftCollection, getNftCollection } from '../network/leaderboard';
import { NETWORK } from '../web3';

export function useNftCollectedQuery({
  chainId,
  secondaryContractAddress
}: {
  chainId: NETWORK,
  secondaryContractAddress: string
}) {

  return useQuery<Array<TNftCollection>>(['nft-collection', chainId, secondaryContractAddress], async () => {
    return (
      await getNftCollection({
        chainId,
        secondaryContractAddress
      })
    );
  },
  {
    refetchInterval: 600000, // refresh every 10 minutes
  });
}
