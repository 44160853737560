import { BASE_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";

export enum LGTContractVersion {
  LEGACY_UNSUPPORTED = 0,
  V3_BASE_URI = 1,
  V3_PSI_BASE_URI = 2,
  V3_PSI_ON_CHAIN_METADATA = 3,
  ERC721_NFT_DISTRIBUTION = 4,
  ERC1155_NFT_DISTRIBUTION = 5,
}

export interface TPublicTemplate {
  id: number;
  updated_at: number;
  created_at: number;
  name: string;
  nft_chain_id?: number;
  nft_contract_address?: string;
  tag_count: number;
  contract_version?: LGTContractVersion;
  secondary_contract_version?: LGTContractVersion;
  secondary_nft_contract_address?: string;
}

export interface TAllContracts {
  contracts: TPublicTemplate[];
  secondary_contracts: TPublicTemplate[];
}

export const getAllContracts = async (
) => {
  try {
    const response = await fetch(`${BASE_URL.PRODUCTION}/external/v1/contracts`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TAllContracts = await response.json();

    return responseBody;
  } catch (e) {
    throw e;
  }
};
