import {ThemeProvider} from "@mui/material";
import { LightTheme } from './utils/theme/MuiTheme'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./App.css"
import SkuList from './SkuList';
import { QueryClient, QueryClientProvider } from 'react-query';
import Item from './Item';

const router = createBrowserRouter([
  {
    path: "/",
    element: <SkuList />,
  },
  {
    path: "/item",
    element: <Item />,
  }
]);

export default function App() {
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={LightTheme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
