import { JsonRpcProvider } from "ethers";
import { useEnsReverseQuery } from "../utils/queries/useEnsReverseQuery";
import { truncateAddress } from "../utils/web3";

const EnsOrAddressDisplay = ({ address, provider }: { address: string, provider: JsonRpcProvider }) => {

  const { data: ensAddress } = useEnsReverseQuery(address, provider);

  return (
    <>{ensAddress || truncateAddress(address)}</>
  );
}

export default EnsOrAddressDisplay;
