import {useQuery} from 'react-query';
import { TAllContracts, getAllContracts } from '../network/contract';

export function useAllSkusQuery() {

  return useQuery<TAllContracts>(['all-contracts'], async () => {
    return (
      await getAllContracts()
    );
  });
}
