import {useQuery} from 'react-query';
import { JsonRpcProvider } from 'ethers';

export function useEnsReverseQuery(
  address: string,
  provider: JsonRpcProvider
) {

  return useQuery<string | null>(['ens-reverse', address], async () => {
    return (
      await provider.lookupAddress(address)
    );
  });
}
