import { Contract, JsonRpcProvider } from "ethers"
import { LGT_WALLET_BY_CHAIN_ID, NETWORK, RPC_URL_BY_CHAIN_ID } from "../web3"

import LGT_PRIMARY_CONTRACT_ABI from "../web3/v3PsiMetadataABI.json"
import { ISimpleHashOwner, getAllNftsInContract, getOwnersbyContract } from "./nft"

export interface TNftDistribution {
  nftsDistributed: number,
  ownerAddress: string,
  tokenId: number,
}

export interface TNftCollection {
  nftsCollected: number,
  ownerAddress: string,
}

export const getNftDistribution = async ({
  chainId,
  primaryContractAddress,
  secondaryContractAddress
}: {
  chainId: NETWORK,
  primaryContractAddress: string,
  secondaryContractAddress: string
}) => {

  const provider = new JsonRpcProvider(RPC_URL_BY_CHAIN_ID[chainId], chainId);

  const primaryContract = new Contract(
    primaryContractAddress,
    LGT_PRIMARY_CONTRACT_ABI,
    provider
  );

  const secondaryNfts = await getAllNftsInContract(secondaryContractAddress, chainId);

  const distribution: Array<TNftDistribution> = [];

  const ownerQueryPromises: Array<Promise<void>> = [];

  for (const nft of secondaryNfts) {
    // avoid async here to parallelize queries
    const ownerQuery = primaryContract.ownerOf(nft.token_id).then((ownerAddress) => {
      if (ownerAddress !== LGT_WALLET_BY_CHAIN_ID[chainId]) {
        distribution.push({
          nftsDistributed: nft.token_count,
          ownerAddress,
          tokenId: Number(nft.token_id),
        });
      }
    });

    ownerQueryPromises.push(ownerQuery);
  }

  await Promise.all(ownerQueryPromises);

  const leaderboard = distribution.sort((a, b) => b.nftsDistributed - a.nftsDistributed);

  return leaderboard;
};

export const getNftCollection = async ({
  chainId,
  secondaryContractAddress
}: {
  chainId: NETWORK,
  secondaryContractAddress: string
}) => {
  const owners = await getOwnersbyContract(secondaryContractAddress, chainId);

  const consolidatedOwners: Record<string, ISimpleHashOwner> = {}

  // there may be multiple entries for the same address because there's a separate entry for each tokenId
  for (const owner of owners) {
    if (consolidatedOwners[owner.owner_address]) {
      consolidatedOwners[owner.owner_address].quantity += owner.quantity;
    } else {
      consolidatedOwners[owner.owner_address] = owner;
    }
  }

  const collection: Array<TNftCollection> = [];

  // now that we've consolidated the owners, we can iterate over them and get the ENS names
  for (const owner of Object.values(consolidatedOwners)) {
    collection.push({
      nftsCollected: owner.quantity,
      ownerAddress: owner.owner_address,
    });
  }

  const leaderboard = collection.sort((a, b) => b.nftsCollected - a.nftsCollected);

  return leaderboard;
}
