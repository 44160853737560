import { NETWORK, SIMPLE_HASH_NETWORK_BY_CHAIN_ID } from "../web3";
import { NetworkRequestError } from "./error";

export interface ISimpleHashNft {
  nft_id: string;
  chain: string;
  contract_address: string;
  token_id: string;
  name: string;
  description: string;
  image_url: string;
  video_url: string;
  token_count: number;
}

export interface ISimpleHashOwner {
  owner_address: string;
  quantity: number;
}

const SIMPLE_HASH_API_KEY = `legitimate_sk_cb24a3ee-b1ae-4b9d-acad-28433473d4f1_xoifvlaj9vds3tlo`

interface ISimpleHashNftResponse {
  next_cursor: string | null;
  next: string | null;
  previous: string | null;
  nfts: Array<ISimpleHashNft>;
}

interface ISimpleHashNftOwnerResponse {
  next_cursor: string | null;
  next: string | null;
  previous: string | null;
  owners: Array<ISimpleHashOwner>;
}

export const getAllNftsInContract = async (contractAddress: string, chainId: NETWORK) => {
  try {
    const nfts: ISimpleHashNft[] = [];

    let cursor = null;

    do {
      const response = await fetch(`https://api.simplehash.com/api/v0/nfts/${SIMPLE_HASH_NETWORK_BY_CHAIN_ID[chainId]}/${contractAddress}?cursor=${cursor}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-API-KEY': SIMPLE_HASH_API_KEY,
        }
      });

      if (!response.ok) {
        throw new NetworkRequestError(await response.json(), response.status);
      }
      const responseBody: ISimpleHashNftResponse = await response.json();

      nfts.push(...responseBody.nfts);
      cursor = responseBody.next_cursor;
    } while (cursor)
    return nfts.sort((a, b) => {
      return a.token_id.localeCompare(b.token_id);
    });
  } catch (e) {
    throw e;
  }
}

export const getOwnersbyContract = async (contractAddress: string, chainId: NETWORK) => {
  try {
    const owners: ISimpleHashOwner[] = [];

    let cursor = null;

    do {
      const response = await fetch(`https://api.simplehash.com/api/v0/nfts/owners/${SIMPLE_HASH_NETWORK_BY_CHAIN_ID[chainId]}/${contractAddress}?cursor=${cursor}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-API-KEY': SIMPLE_HASH_API_KEY,
        }
      });

      if (!response.ok) {
        throw new NetworkRequestError(await response.json(), response.status);
      }
      const responseBody: ISimpleHashNftOwnerResponse = await response.json();

      owners.push(...responseBody.owners);
      cursor = responseBody.next_cursor;
    } while (cursor)

    return owners;
  } catch (e) {
    throw e;
  }
}
